"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartialSchedulingRulesSchema = exports.SchedulingRulesSchema = void 0;
const zod_1 = require("zod");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const HH_MM_REGEX = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
const ISO_WEEKDAY_HH_MM_REGEX = /^([1-7]) ([01][0-9]|2[0-3]):[0-5][0-9]$/;
const SchedulingRulesSchema = zod_1.z
    .object({
    recommendedConsecutiveShifts: zod_1.z.number().min(0).max(10),
    maximumConsecutiveShifts: zod_1.z.number().min(0).max(10),
    recommendedMaximumRestDays: zod_1.z.number().min(0).nullable(),
    minimumNightToDayRest: zod_1.z.number().min(0),
    minimumRestBetweenShifts: zod_1.z.number().min(0),
    minimumDailyRest: zod_1.z.number().min(0),
    recommendedDailyRest: zod_1.z.number().min(0),
    minimumWeeklyRest: zod_1.z.number().min(0),
    commonDayBreak: zod_1.z.string().regex(HH_MM_REGEX).nullable(),
    maxWeeklyWorkTime: zod_1.z.number().min(0).nullable(),
    maxOutgoingHours: zod_1.z.number().min(0).nullable(),
    maxExtraScheduledMinutes: zod_1.z.number().min(0),
    shortDayLength: zod_1.z.number().min(0),
    noShortDays: zod_1.z.boolean(),
    longestDayLength: zod_1.z.number().min(0).max((0, utils_1.hoursAsMinutes)(24)),
    defaultWeekendFrequency: zod_1.z.string().regex(/^[0-9]+\/[0-9]+$/),
    weekendStart: zod_1.z.string().regex(ISO_WEEKDAY_HH_MM_REGEX),
    weekendEnd: zod_1.z.string().regex(ISO_WEEKDAY_HH_MM_REGEX),
    minLengthPaddingShifts: zod_1.z.number().min(0).nullable(),
    extendedLeave: zod_1.z
        .object({
        periodsPerSchedule: zod_1.z.number().min(0),
        daysPerPeriod: zod_1.z.number().min(0),
        canStartOn: zod_1.z.array(zod_1.z.nativeEnum(enums_1.Day)).nullable(),
        previousDayLatestEnd: zod_1.z.string().regex(HH_MM_REGEX).nullable(),
    })
        .nullable(),
    noFlexShiftsIfVacancies: zod_1.z.boolean(),
    penalties: zod_1.z.record(zod_1.z.nativeEnum(enums_1.SchedulerPenaltyType), zod_1.z.number()),
    useHokRules: zod_1.z.boolean(),
})
    .strict();
exports.SchedulingRulesSchema = SchedulingRulesSchema;
const PartialSchedulingRulesSchema = SchedulingRulesSchema.partial();
exports.PartialSchedulingRulesSchema = PartialSchedulingRulesSchema;
